import type { ReactNode } from 'react'
import { buttonVariants } from '@rouvydev/web-components'
import { NormalizedLink } from '~/components/normalized-link.tsx'

type Props = {
  children: ReactNode
  url: string
  external?: boolean
}

export function LinkButton({ children, url, external }: Props) {
  if (!external && !url.startsWith('http')) {
    return (
      <NormalizedLink
        className={buttonVariants({
          variant: 'primary',
        })}
        to={url}
      >
        {children}
      </NormalizedLink>
    )
  }

  return (
    <a
      rel={external ? 'noopener noreferrer' : undefined}
      href={url}
      target={external ? '_blank' : undefined}
      className={buttonVariants({
        variant: 'primary',
      })}
    >
      {children}
    </a>
  )
}

export let scheme = {
  render: LinkButton.name,
  description: 'Displays link as button',
  children: ['node'],
  attributes: {
    url: {
      type: String,
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
}
